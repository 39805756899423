import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import UploadFiles from './upload-files';

class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          files: []
        }     
    }
    render() {
        
        return (
            <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Project Details
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <TextField id="projectName" name="projectName"
                    label="Project Name" fullWidth variant="standard"
                    onChange={this.props.updateProjectDetails}
                    value={this.props.projectDetails.projectName}/>
                </Grid>
                <Grid item xs={12}>
                <TextField id="projectDesc" name="projectDesc" 
                    label="Description" fullWidth multiline={true} minRows={5}
                    onChange={this.props.updateProjectDetails}
                    value={this.props.projectDetails.projectDesc} />
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField id="budget" name="budget" 
                                label="Budget" fullWidth variant="standard"
                                onChange={this.props.updateProjectDetails}
                                value={this.props.projectDetails.budget} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="timeframe_val" name="timeframe" 
                                label="Time Frame" fullWidth variant="standard"
                                onChange={this.props.updateProjectDetails}
                                value={this.props.projectDetails.timeframe} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="timeframe_item-label">Time Period</InputLabel>
                                <Select labelId="timeframe_item" id="timeframe_item"
                                    label="Time Frame" 
                                    value={this.props.projectDetails.timeframe_item}
                                    onChange={this.props.handleTimePeriodChange}>
                                    <MenuItem value={"select"}>Select one ...</MenuItem>
                                    <MenuItem value={"week"}>Weeks</MenuItem>
                                    <MenuItem value={"month"}>Months</MenuItem>
                                    <MenuItem value={"year"}>Years</MenuItem>                                    
                                </Select>
                            </FormControl>                            
                        </Grid>            
                    </Grid>        
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <UploadFiles projectID={this.props.projectID} 
                               projectDetails={this.props.projectDetails} 
                               updateProjectFiles={this.props.updateProjectFiles}/>
                        
                        </Grid>
                    </Grid>        
                </Grid>
                
                <Grid item xs={12} sm={6}/>                
                            
            </Grid>
            </React.Fragment>
        );        
    }
}
export default ProjectDetails