import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import UploadFiles from './upload-files';

class ReferralSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          files: []
        }     
    }
    render() {        
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Referral Summary
                </Typography>
                <div className="summary_section">
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <TextField id="businessName" name="businessName"
                                label="Business Name" fullWidth variant="standard"
                                value={this.props.businessDetails.businessName}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="websiteURL" name="websiteURL" 
                                label="Website" fullWidth variant="standard"
                                value={this.props.businessDetails.websiteURL} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="city" name="city" 
                                label="City" fullWidth variant="standard"
                                value={this.props.businessDetails.city} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="industry" name="industry" 
                                label="Industry" fullWidth variant="standard"
                                value={this.props.businessDetails.industry} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="postcode" name="postcode" 
                                label="Postcode" fullWidth variant="standard"
                                value={this.props.businessDetails.postcode} />
                        </Grid>     
                    </Grid>
                </div>
                <div className="summary_section">
                    <Grid container spacing={0}>
                        {this.props.contacts.map((contact, index) => (
                            <Grid item xs={6} key={index}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField id="contactName" name="contactName"
                                            label="Contact Name" fullWidth variant="standard"
                                            value={contact.firstname +" " + contact.lastname} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="email" name="email"
                                            label="Email" fullWidth variant="standard"
                                            value={contact.email} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="phone" name="phone"
                                            label="Phone" fullWidth variant="standard"
                                            value={contact.phone} />
                                    </Grid>
                                </Grid>

                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div className="summary_section">
                    <Grid container spacing={3}>
                        <Grid item xs={6} >
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <TextField id="projectName" name="projectName"
                                        label="Project Name" fullWidth variant="standard"
                                        value={this.props.projectDetails.projectName}/>
                                </Grid>                                
                                <Grid item xs={6}>
                                    <TextField id="budget" name="budget" 
                                        label="Budget" fullWidth variant="standard"
                                        value={this.props.projectDetails.budget} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="timeframe" name="timeframe" 
                                        label="Time Frame" fullWidth variant="standard"
                                        value={this.props.projectDetails.timeframe} />
                                </Grid>
                                <Grid item xs={12} >
                                    <InputLabel id="filesLabel" style={{textAlign:"start"}}>Files: </InputLabel>
                                    <ul>
                                    {this.props.projectDetails.files.map((file, index) => {
                                        var filekey = file.split("/")[1]
                                        if(filekey.length > 30) {filekey = filekey.substring(0, 30) + "..."}
                                        return (
                                            <li key={index} className='file_list_items'>
                                                {filekey}
                                            </li>
                                            )}
                                        )
                                    }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="projectDesc" name="projectDesc" 
                                label="Description" fullWidth multiline={true} minRows={5}
                                value={this.props.projectDetails.projectDesc} />
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );        
    }
}
export default ReferralSummary