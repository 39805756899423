import React from "react";
import AWS from "aws-sdk";
import Grid from '@mui/material/Grid';
import { FaCheck } from "react-icons/fa6";
import configData from "../assets/config.json"


class S3FileUploader extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        file: null,
        completed: false,
        percentage: 0
      }
      this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile = async (file) => {
    
    const S3_BUCKET = "maventech-referraluploads";
    const REGION = "ap-southeast-2";
    // console.log(file)
    AWS.config.update({
      accessKeyId: configData.ACCESS_KEY,
      secretAccessKey: configData.SECRET_KEY,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: this.props.projectID + "/" +file.name,
      Body: file,
    };
    var upload = s3.putObject(params).on("httpUploadProgress", (evt) => {
      var percent = parseInt((evt.loaded * 100) / evt.total);
      this.setState({ percentage: percent });
    }).promise();
    await upload.then((err, data) => {
      console.log(err);
      // console.log(this.props.projectDetails)
      this.props.updateProjectFiles(this.props.projectID + "/" +file.name)
      this.setState({ completed: true });
    });
  }
  componentDidMount() {
    setTimeout(() => {
      this.uploadFile(this.props.file);
    }, 2500)
  }

  render() {
    // console.log(this.props.projectDetails)
    var filenname = this.props.file.name;
    var path = this.props.projectID + "/"
    if(filenname.length > 30) {filenname = filenname.substring(0, 30) + "..."}
  
    return (
      <div className="s3_fileuploader_container">
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {filenname}
              </Grid>
              <Grid item xs={12}>
                {path}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <div className="s3_fileuploader_progressbar">
              {this.state.percentage<100 ?
                <div className="s3_fileuploader_progressbar_percentage">
                  {this.state.percentage}%
                </div>
                :
                <div className="s3_fileuploader_progressbar_completed">
                  <FaCheck />
                </div>
               }
            </div>
          </Grid>
        </Grid>
        
        
      </div>)
  }
}
export default S3FileUploader;