import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { AiOutlineClose } from "react-icons/ai";
import { FaCirclePlus } from "react-icons/fa6";



class ContactItem extends React.Component {
    render() {        
        return (
            <React.Fragment>
                <Grid container spacing={0} className='contact_item'>                
                    <Grid item xs={12} >
                        <AiOutlineClose className='close_button' 
                        id={this.props.contactDetail.id}
                        onClick={this.props.removeContact}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField required id={this.props.contactDetail.id+"_firstname"} name="firstname" 
                            label="First Name" fullWidth variant="standard"
                            onChange={this.props.updateContacts} 
                            value={this.props.contactDetail.firstname}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField required id={this.props.contactDetail.id+"_lastname"} name="lastname" 
                            label="Last Name" fullWidth variant="standard"
                            onChange={this.props.updateContacts}
                            value={this.props.contactDetail.lastname}/>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                    <TextField id={this.props.contactDetail.id+"_email"} name="email" label="Email"
                        required fullWidth variant="standard"
                        onChange={this.props.updateContacts}
                        value={this.props.contactDetail.email}/>
                    </Grid>
                    <Grid item xs={12} sm={6} />
                    <Grid item xs={12} sm={6}>
                    <TextField id={this.props.contactDetail.id+"_phone"} name="phone" label="Phone"
                        required fullWidth variant="standard"
                        onChange={this.props.updateContacts}
                        value={this.props.contactDetail.phone}/>
                    </Grid>
                </Grid>
            </React.Fragment>            
        )
    }
}
class ContactDetails extends React.Component {
    render() {        
        return (
            <React.Fragment>
                <Grid container spacing={3} className='section_heading'>
                    <Grid item xs={11} >
                        <Typography variant="h6" gutterBottom style={{padding:"1vmin"}}>
                            Contacts
                        </Typography>
                    </Grid>
                    <Grid item xs={1} >
                        <FaCirclePlus className='add_button' onClick={this.props.addContact} />
                    </Grid>
                </Grid>                
                {this.props.contacts.map((contact, index) => (
                    <ContactItem key={index} contactDetail={contact}
                        removeContact={this.props.removeContact}
                        updateContacts={this.props.updateContacts} />))}                                    
            </React.Fragment>
        ); 
    }       
}

export default ContactDetails