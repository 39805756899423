import React from 'react';
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Header } from "./components/auth/Header";
import { Footer } from "./components/auth/Footer";
import { SignInHeader } from "./components/auth/SignInHeader";
import { SignInFooter } from "./components/auth/SignInFooter";
import Container from '@mui/material/Container';
import ReferralPage from './components/general/referralform';
import './assets/App.css';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);
export function App({ signOut, user }) {
  // console.log(user)
  return (
    <main>
      
      <Container maxWidth="100vw">        
        <ReferralPage  user={user} signOut={signOut}/>
      </Container>
      </main>
  );
}

export default withAuthenticator(App, {
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <ReferralPage />
//       </header>
//     </div>
//   );
// }
// export default App;
