import * as React from "react"
import { v4 as uuid } from "uuid";
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import BusinessDetails from './business_details'
import ContactDetails from './contact_details'
import ProjectDetails from './project_details'
import ReferralSummary from './summary'
import ThankYouPage from './thankyou'
import base_logo from '../../assets/base_logo.png'
import ReCAPTCHA from 'react-google-recaptcha'
import { Grid } from "@mui/material";
import "../../assets/App.css"
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#7800ff',
      main: '#7800ff',
      dark: '#7800ff',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7800ff',
      main: '#7800ff',
      dark: '#7800ff',
      contrastText: '#000',
    },
  },
});

const BASE_API = "https://brks3ksmb1.execute-api.ap-southeast-2.amazonaws.com/v1/"
const RECAPTCHA = process.env.RECAPTCHAKEY
const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://workwithmavens.io/">
          Maven Technologies
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}
  
const steps = ['Business Details', 'Contact Details', 'Project Details', 'Summary'];
 
class ReferralPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,
      name: '',
      phone: '',
      email: '',
      message: '',
      submitted: false,
      activeStep: 0,
      industry: '',
      recaptcha: '',
      projectID: uuid(),
      businessDetails :{
        businessName: '',
        websiteURL: '',
        city: '',
        postcode: '',
        industry: 'select'
      },
      contacts: [
        {
          id: 1,
          firstname: '',
          lastname: '',
          phone: '',
          email: ''
        }
      ],
      projectDetails:{
        projectName: '',
        projectDesc: '',
        budget: '',
        timeframe_val: '',
        timeframe_item: 'select',
        files: []
      }
    }
    // this.recaptchatoken = this.recaptchatoken.bind(this)
    this.setActiveStep = this.setActiveStep.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addContact = this.addContact.bind(this);
    this.removeContact = this.removeContact.bind(this);
    this.updateContacts = this.updateContacts.bind(this);
    this.updateBusinessDetails = this.updateBusinessDetails.bind(this);
    this.updateProjectDetails = this.updateProjectDetails.bind(this);
    this.updateProjectFiles = this.updateProjectFiles.bind(this);
    this.handleTimePeriodChange = this.handleTimePeriodChange.bind(this);

    this.getStepContent = this.getStepContent.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
  }
  setActiveStep = (step) => {
    this.setState({ activeStep: step })
  }
  handleNext = () => {
    this.setActiveStep(this.state.activeStep + 1);
  }
  handleBack = () => {     
    this.setActiveStep(this.state.activeStep - 1);    
  }
  handleIndustryChange(event) {
    var businessDetails = this.state.businessDetails;
    businessDetails.industry = event.target.value;
    this.setState({ businessDetails: businessDetails})
  }
  handleTimePeriodChange(event) {
    var projectDetails = this.state.projectDetails;
    projectDetails.timeframe_item = event.target.value;
    this.setState({ projectDetails: projectDetails})
  }
  removeContact(event) {
    event.preventDefault()
    if(this.state.contacts.length > 1) {
      var contacts = this.state.contacts;
      for(var i=0; i<contacts.length; i++) {
        if(contacts[i].id == event.target.id) {
          contacts.splice(i, 1)
        }
      }
      this.setState({contacts: contacts})
    } 
  }
  addContact(event) {
    event.preventDefault()
    var new_id = this.state.contacts[this.state.contacts.length-1].id + 1
    var contacts = this.state.contacts;
    contacts.push({id: new_id, firstname: '', lastname: '', phone: '', email: ''})
    this.setState({contacts: contacts})
  }
  updateContacts(event) {    
    var contacts = this.state.contacts;
    var id = event.target.id.split("_")[0]
    var field = event.target.id.split("_")[1]


    for(var i=0; i<contacts.length; i++) {
      if(contacts[i].id == parseInt(id)) {
        contacts[i][field] = event.target.value
      }
    }
    this.setState({contacts: contacts})
  }
  updateBusinessDetails(event) {
    // console.log(event.target.id +" "+ event.target.value)
    var businessDetails = this.state.businessDetails;
    businessDetails[event.target.id] = event.target.value;
    this.setState({ businessDetails: businessDetails})
  }
  updateProjectDetails(event) {
    // console.log(event.target.id +" "+ event.target.value)
    var projectDetails = this.state.projectDetails;
    projectDetails[event.target.id] = event.target.value;
    if(event.target.id == "budget") {
      if(projectDetails.budget.length == 1) {
        projectDetails.budget = "$ " + projectDetails.budget
      }
    }
    
    this.setState({ projectDetails: projectDetails})
    // console.log(this.state.projectDetails)
  }
  updateProjectFiles(filePath) {
    // console.log(filePath)
    // console.log(this.state.projectDetails)
    var projectDetails = this.state.projectDetails;
    projectDetails.files.push(filePath);
    this.setState({ projectDetails: projectDetails})
  }
  getStepContent(step) {
    switch (step) {
      case 0:
        return <BusinessDetails 
          businessDetails={this.state.businessDetails}
          updateBusinessDetails={this.updateBusinessDetails}
          handleIndustryChange={this.handleIndustryChange}/>;
      case 1:
        return <ContactDetails 
          addContact={this.addContact} 
          removeContact={this.removeContact} 
          contacts={this.state.contacts} 
          updateContacts={this.updateContacts}/>;
      case 2:
        return <ProjectDetails 
          projectID={this.state.projectID}
          projectDetails={this.state.projectDetails}
          updateProjectDetails={this.updateProjectDetails}
          updateProjectFiles={this.updateProjectFiles}
          handleTimePeriodChange={this.handleTimePeriodChange} />;  
      case 3:
        return <ReferralSummary 
          projectID={this.state.projectID}
          businessDetails={this.state.businessDetails}
          contacts={this.state.contacts} 
          projectDetails={this.state.projectDetails} 
          handleSubmit={this.handleSubmit}/>; 
      case 4:
        return <ThankYouPage /> 
      default:
        throw new Error('Unknown step');
    }
  }    
  handleSubmit = (event) => {
    event.preventDefault()    
    let formData = {}
    formData['user'] = "default"
    formData['projectID'] = this.state.projectID
    formData['businessDetails'] = this.state.businessDetails
    formData['contacts'] = this.state.contacts
    formData['projectDetails'] = this.state.projectDetails
    formData['subject'] = 'Referral Form Submission'    

    let headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Origin', '*')
    var requestConfig = {
      method: 'POST',
      body: JSON.stringify(formData),
      mode: 'cors',
      cache: 'no-cache',
      headers: headers,
    }
    fetch(BASE_API + 'referral', requestConfig)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        this.setState({ submitted: true })
        this.setState({ activeStep: 4 })      
      })
      .catch((error) => {
        console.log(error)
      })    
  }
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
    if(this.props.projectID) {
      this.setState({projectID: uuid()})
    }
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }
  render() {
    // console.log(RECAPTCHA)
    return (  
        <React.Fragment>
            {/* <CssBaseline /> */}
            <ThemeProvider theme={theme}>                       
              <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                  <Grid container spacing={0}>
                      <Grid item xs={12}>
                          <Link color="inherit" href="https://quantal.ai/">
                              <img className="banner_logo" src={base_logo} alt="Quantal AI"/>
                          </Link>
                      </Grid>
                  </Grid>
                  <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                  <Typography component="h1" variant="h4" align="center">
                      Referral Form
                  </Typography>
                  <Stepper activeStep={this.state.activeStep} sx={{ pt: 3, pb: 5 }}>
                      {steps.map((label) => (
                      <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                      </Step>
                      ))}
                  </Stepper>
                  {this.state.activeStep === steps.length ? (
                      <React.Fragment>
                      {this.getStepContent(this.state.activeStep)}                      
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} />                      
                      </React.Fragment>
                  ) : (
                      <React.Fragment>
                      {this.getStepContent(this.state.activeStep)}

                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          {this.state.activeStep !== 0 && (
                          <Button onClick={this.handleBack} sx={{ mt: 3, ml: 1 }}>
                              Back
                          </Button>
                          )}
                          {this.state.activeStep === steps.length - 1 ? 
                          <Button variant="contained" onClick={this.handleSubmit} sx={{ mt: 3, ml: 1 }}>Submit Referral</Button>
                          :
                          <Button variant="contained" onClick={this.handleNext} sx={{ mt: 3, ml: 1 }}>Next</Button>
                          }
                      </Box>
                      </React.Fragment>
                  )}
                  </Paper>
                  <Copyright />
              </Container>
            </ThemeProvider>
        </React.Fragment>
      
    )
  }
}

export default ReferralPage
