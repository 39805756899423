import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import UploadFiles from './upload-files';

class ThankYouPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          files: []
        }     
    }
    componentDidMount() {
        setTimeout(() => {
            window.location.href = "https://workwithmavens.io"
        }, 10000)
    }
    render() {        
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Thank you
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Your referral has been submitted successfully. One of our team members will be in touch with you
                    shortly to discuss any updates or next steps. Thank you again. 
                </Typography>
                
            </React.Fragment>
        );        
    }
}
export default ThankYouPage