import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Dropzone from 'react-dropzone';
import S3FileUploader from '../../services/file-upload-service'
import { S3 } from 'aws-sdk';

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);    
    this.onDrop = this.onDrop.bind(this);

    this.state = {
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: '',
      fileUploaders: [],
    };
  }

  onDrop(files) {    
    if (files.length > 0) {        
        if(this.state.selectedFiles === undefined) {
            var currentFiles = [];
        } else {
            var currentFiles = this.state.selectedFiles;
        }
        for(var i = 0; i < files.length; i++) {
            currentFiles.push(files[i])
        }
        this.setState({ selectedFiles: currentFiles });        
    }
    var fileUploaders = [];
    fileUploaders.push(<S3FileUploader 
        file={files[0]}  
        projectID={this.props.projectID}
        projectDetails={this.props.projectDetails}
        updateProjectFiles={this.props.updateProjectFiles}/>);
    this.setState({ fileUploaders: fileUploaders });    
  }

  render() {
    const { selectedFiles, currentFile, progress, message, fileUploaders } =
      this.state;
    return (
      <div>
        <Dropzone onDrop={this.onDrop} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {selectedFiles && selectedFiles[0].name ? (
                  <div className="selected-file">
                    {fileUploaders.map((fileUploader, index) => (
                        <div key={index}>
                            {fileUploader}
                        </div>
                    ))}
                  </div>
                ) : (
                  'Drag and drop file here, or click to select file'
                )}
              </div>
            </section>
          )}
        </Dropzone>        
      </div>
    );
  }
}
