import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class BusinessDetails extends React.Component {
    render() {
        return (
            <React.Fragment>
                
                <Typography variant="h6" gutterBottom>
                    Business Details
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                    <TextField required id="businessName" name="businessName" 
                        label="Business Name" fullWidth variant="standard" 
                        value={this.props.businessDetails.businessName}
                        onChange={this.props.updateBusinessDetails} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField required id="websiteURL" name="website" 
                        label="Website URL" fullWidth variant="standard"
                        value={this.props.businessDetails.websiteURL}
                        onChange={this.props.updateBusinessDetails} />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                    <TextField id="city" name="city"
                        label="City" fullWidth variant="standard"
                        value={this.props.businessDetails.city}
                        onChange={this.props.updateBusinessDetails} />
                    </Grid>
                    <Grid item xs={12} sm={6} />
                    <Grid item xs={12} sm={6}>
                    <TextField id="postcode" name="postcode" 
                        label="Post Code" fullWidth variant="standard" 
                        value={this.props.businessDetails.postcode}
                        onChange={this.props.updateBusinessDetails}/>
                    </Grid>
                    <Grid item xs={12} sm={6} />
                    <Grid item xs={12} sm={6} className='dropdown_select'>
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="industry-select-label">Industry</InputLabel>
                            <Select labelId="industry-select" id="industry-select"
                                label="Industry" 
                                value={this.props.businessDetails.industry}
                                onChange={this.props.handleIndustryChange}>
                                <MenuItem value={"select"}>Select one ...</MenuItem>
                                <MenuItem value={"ag_and_forest"}>Agriculture and Forestry</MenuItem>
                                <MenuItem value={"auto"}>Automotive</MenuItem>
                                <MenuItem value={"bank_fin"}>Banking and Finance</MenuItem>
                                <MenuItem value={"biotech"}>Biotechnology</MenuItem>
                                <MenuItem value={"construct"}>Construction</MenuItem>
                                <MenuItem value={"consumer"}>Consumer Goods</MenuItem>
                                <MenuItem value={"education"}>Education</MenuItem>
                                <MenuItem value={"energy"}>Energy</MenuItem>
                                <MenuItem value={"entertainment"}>Entertainment and Media</MenuItem>
                                <MenuItem value={"fashion"}>Fashion and Apparel</MenuItem>
                                <MenuItem value={"food_bev"}>Food and Beverage</MenuItem>
                                <MenuItem value={"government"}>Government</MenuItem>
                                <MenuItem value={"health"}>Healthcare and Pharmaceuticals</MenuItem>
                                <MenuItem value={"hospitality"}>Hospitality and Tourism</MenuItem>
                                <MenuItem value={"info_tech"}>Information Technology</MenuItem>
                                <MenuItem value={"legal"}>Legal Services</MenuItem>
                                <MenuItem value={"manufacturing"}>Manufacturing and Industrial</MenuItem>
                                <MenuItem value={"marketing"}>Marketing and Advertising</MenuItem>
                                <MenuItem value={"nonprofit"}>Non-Profit and NGO</MenuItem>
                                <MenuItem value={"professional"}>Professional Services</MenuItem>
                                <MenuItem value={"retail"}>Retail</MenuItem>
                                <MenuItem value={"telecommunications"}>Telecommunications</MenuItem>
                                <MenuItem value={"transportation"}>Transportation and Logistics</MenuItem>
                                <MenuItem value={"utilities"}>Utilities</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>        
                </Grid>
                
            </React.Fragment>
        ); 
    }       
}

export default BusinessDetails



















































